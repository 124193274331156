import React from 'react';

import {Box, Typography} from '@mui/material';
import {useWeb3React} from '@web3-react/core';
import {utils} from 'ethers';
import moment from 'moment';

import ClaimedProgress from '../../components/ClaimedProgress';
import {formatPercentage} from '../../lib/format';
import {useAppSelector} from '../../redux/hooks';
import {termsSelector} from '../../redux/slices/stakeTerms';
import {
    totalClaimedRewardsSelector,
    totalVestedRewardsSelector,
} from '../../redux/slices/totalsOfAdvancedStakes';
import {getAdvStakingAPY} from '../../services/rewards';
import {StakeType} from '../../types/staking';

import './styles.scss';

function AdvancedStakingRewards() {
    const claimed = useAppSelector(totalClaimedRewardsSelector);
    const total = useAppSelector(totalVestedRewardsSelector);
    const advancedStakingAPY = getAdvStakingAPY(new Date().getTime());

    return (
        <Box className="advanced-staking-rewards">
            <ClaimedProgress
                claimed={claimed ? Number(utils.formatEther(claimed)) : 0}
                total={total ? Number(utils.formatEther(total)) : 0}
            />

            <RemainingDays />
            {advancedStakingAPY && (
                <StakingAPR advancedStakingAPY={advancedStakingAPY} />
            )}
        </Box>
    );
}

function calcRemainingDays(
    _allowedSince: number,
    _allowedTill: number,
): [string, string] {
    const now = moment();
    const allowedSince = moment(_allowedSince * 1000);
    const allowedTill = moment(_allowedTill * 1000);

    if (moment(allowedSince).isSameOrAfter(allowedTill)) {
        return ['', '?'];
    }
    if (moment(now).isSameOrBefore(allowedSince)) {
        return ['Opening', now.to(allowedSince)];
    }
    if (moment(now).isBetween(allowedSince, allowedTill)) {
        return ['Closing', now.to(allowedTill)];
    }
    if (moment(now).isAfter(allowedTill)) {
        return ['Staking is closed', ''];
    }
    return ['', '?'];
}

function RemainingDays() {
    const context = useWeb3React();
    const {chainId} = context;

    const allowedSince = useAppSelector(
        termsSelector(chainId!, StakeType.Advanced, 'allowedSince'),
    );

    const allowedTill = useAppSelector(
        termsSelector(chainId!, StakeType.Advanced, 'allowedTill'),
    );

    const [title, daysRemaining]: [string, string] =
        typeof allowedTill === 'number' && typeof allowedSince === 'number'
            ? calcRemainingDays(allowedSince, allowedTill)
            : ['', '?'];

    return (
        <Box className="remaining-days">
            <Typography className="text">{title}</Typography>
            <Typography className="value">{daysRemaining}</Typography>
        </Box>
    );
}

function StakingAPR(props: {advancedStakingAPY: number}) {
    return (
        <Box className="staking-apr">
            <Typography className="text">Staking APR</Typography>
            <Typography className="value">
                {formatPercentage(props.advancedStakingAPY / 100)}
            </Typography>
        </Box>
    );
}

export default AdvancedStakingRewards;
