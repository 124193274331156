import React from 'react';

import {MainPageWrapper} from '../../components/MainPageWrapper';
import ZafariFaucet from '../../components/ZafariFaucet';

const Faucet = () => {
    return (
        <MainPageWrapper>
            <ZafariFaucet />
        </MainPageWrapper>
    );
};

export default Faucet;
